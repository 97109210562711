<template>
    <v-card>
        <v-card-title
            class="indigo white--text"
        >
            <h3>Pedido Nº {{history[0].id}}</h3>

            <v-spacer></v-spacer>

            <h3>Total R$ {{history[0].valorTotal}}</h3>
        </v-card-title>

        <v-card-text class="pt-8" >
            <h2 class="ml-4" >Informações do pedido</h2>
            <v-divider class="mt-4 mb-2" ></v-divider>
            <p>
                {{ 'Pedido em ' + $moment(history[0].criadoEm).format('DD/MM/YYYY [ás] hh:mm')}}
            </p>
            <p>
                Pedido para <strong>{{history[0].entrega === 'S' ? 'Entrega' : 'Retirada'}}</strong>
            </p>
            <p>
                Status do pedido. <strong>{{status[history[0].status]}}</strong>
            </p>
            <p v-if="history.entrega === 'S'" >
                Endereço para entrega: <strong>{{history[0].endereco}}</strong>
            </p>
            <p v-else >
                Endereço para retirada: <strong>{{history[0].endereco}}</strong>
            </p>
        </v-card-text>
        <v-card-text>
            <v-divider class="mb-4" ></v-divider>
            <h2 class="ml-4" >Itens do pedido</h2>
            <v-divider class="mt-4 mb-2" ></v-divider>

            <ul>
                <li v-for="item in history" :key="item.id_item" >
                    {{item.produtoQuantidade + 'x - ' + item.produto + ' - R$' + item.produtoTotal}}
                </li>
            </ul>
        </v-card-text>

        <v-overlay :value="overlay">
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>
        </v-overlay>
    </v-card>
</template>
<script>
    import { mapActions } from 'vuex'
    export default {
        data(){
            return {
                overlay: false,
                history: [{
                    
                }],

                status: {
                    "open" : "Recebido",
                    "prod" : "Em produção",
                    "deli" : "A caminho",
                    "clos" : "Concluido",
                    "dclo" : "Não entregue"
                }
            }
        },

        computed: {
            id(){
                return this.$route.params.id
            }
        },

        methods: {
            ...mapActions({
                getHistory: 'HISTORICO_PEDIDO'
            }),

            getData: function(){
                this.overlay = true
                this.getHistory(this.id)
                    .then(res => (res.json()))
                    .then(resJSON => {
                        if (resJSON.data) {
                            this.history = resJSON.response
                        } else {
                            console.log('Error', '=>', resJSON.message)
                        }
                    })
                    .catch(error => {
                        console.error('Exception', '=>', error)
                    })
                    .finally(() => {
                        this.overlay = false
                    })
            }
        },

        mounted(){
            this.getData()
        }
    }
</script>